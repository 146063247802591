































import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/common/Modal.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import { IEmailReminder } from '@/store/modules/walkthrough/types/demographic/emailReminder'

interface IUserInfo{
  flyingDate: string;
}

@Component({
  components: {
    Modal,
    DatePicker
  }
})

export default class Step4 extends Vue {
  private flyingDate = DatePicker
  private isModal = false
  private toDate = ''
  private flDate = ''
  private isDisabled = true

  private disabledBeforeToday (date: Date): boolean {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    return date < today || date > new Date(today.getTime() + 2 * 24 * 3600 * 1000)
  }

  private updateVal (): void {
    var prevState = this.$store.getters['walkthrough/userInfo']
    this.flDate = moment(this.flyingDate as string).format('YYYY-MM-DD')
    if (this.flDate !== '') {
      prevState.flyingDate = this.flDate
      this.$store.dispatch('walkthrough/setUserInfo', prevState)
      this.checkDate()
    }
  }

  checkDate ():void {
    if (this.flDate === '') return
    var flyDate = this.flDate.replace(/-/g, '/')
    var toDateWithFormat = this.toDate.replace(/-/g, '/')

    var d1 = new Date(flyDate)
    var d2 = new Date(toDateWithFormat)

    var diff = d1.getTime() - d2.getTime()
    var daydiff = diff / (1000 * 60 * 60 * 24)

    if (daydiff > 2) {
      this.isModal = true
      this.isDisabled = true
    } else {
      this.isDisabled = false
    }
  }

  private created (): void{
    this.toDate = new Date().toJSON().slice(0, 10)
  }

  private mounted (): void {
    const userInfo:IUserInfo = this.$store.getters['walkthrough/userInfo']
    this.flyingDate = new Date(userInfo.flyingDate)
    this.checkDate()
  }

  async goTo (): Promise<void> {
    await this.$store.dispatch('walkthrough/sendDemographic')
    this.$router.push({ name: 'pre test video' })
  }

  closeModal (): void {
    this.isModal = false
    this.sendMeReminder()
  }

  private sendMeReminder (): void {
    var flyDate = this.flDate.replace(/-/g, '/')
    const pastDateWithTimeZone = moment(flyDate).subtract(3, 'days').format('YYYY-MM-DD') + 'T12:00:00'
    const data:IEmailReminder = {
      recipient: localStorage.getItem('newEmail') as string,
      sender: 'QVue <no-reply@bellaloves.me>',
      send_at: pastDateWithTimeZone, // 'PT5M',
      email_template_id: 'd-aec337e273874e219f94082d48124404',
      email_data: {
      }
    }
    console.log('IEmailReminder:', data)
    this.$store.dispatch('walkthrough/sendMeReminder', data)
      .then(response => console.info('sentEmailReminder:', response))
      .catch(error => {
        console.error(error)
      })
  }
}
