






















import { Component, Vue } from 'vue-property-decorator'
import SelectBox from '@/components/SelectBox.vue'
import { PrePared } from '@/store/modules/walkthrough/types/pre-test/prePared'
import { HandsClean } from '@/store/modules/walkthrough/types/pre-test/handsClean'
@Component({
  components: {
    SelectBox
  }
})
export default class Step4 extends Vue {
  private selectedPrepare=''
  private selectedHandsClean=''

  private mounted (): void {
    const previouslySelectedPrepareValue = this.$store.getters['walkthrough/PreParedState']
    const selectedpreItem = previouslySelectedPrepareValue === 1 ? 'Yes' : previouslySelectedPrepareValue === 0 ? 'No' : ''
    const previouslyHandsCleanValue = this.$store.getters['walkthrough/HandsCleanState']
    const selectedhcItem = previouslyHandsCleanValue === 1 ? 'Yes' : previouslyHandsCleanValue === 0 ? 'No' : ''

    if (previouslySelectedPrepareValue !== '') {
      (this.$refs.preselectBox as SelectBox).setVal(selectedpreItem)
      this.selectedPrepare = selectedpreItem
    }

    if (previouslyHandsCleanValue !== '') {
      (this.$refs.hcselectBox as SelectBox).setVal(selectedhcItem)
      this.selectedHandsClean = selectedhcItem
    }
  }

  private updateValPrepare (ev: string): void {
    this.selectedPrepare = ev
    console.log('selectedPrepare', ev)
    this.$store.dispatch('walkthrough/setPrePared', this.selectedPrepare === 'Yes' ? PrePared.Yes : PrePared.No)
  }

  private updateValHandsClean (ev: string): void {
    this.selectedHandsClean = ev
    this.$store.dispatch('walkthrough/setHandsClean', this.selectedPrepare === 'Yes' ? HandsClean.Yes : HandsClean.No)
  }

  private goTo (): void {
    this.$router.push({ name: 'test in progress' })
  }
}
